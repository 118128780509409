import React from 'react'
import PropTypes from 'prop-types'

const DeliverySvg = props => {
  const { className, category, action, label } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="32"
      height="23"
      viewBox="0 0 48 24"
      role="presentation"
      aria-hidden="true"
      gtm-category={category}
      gtm-action={action}
      gtm-label={label}
    >
      <path d="M47.2,14.1l-2.9-1.35-4.94-6a1.4,1.4,0,0,0-1.06-.49H34.76V1.38A1.38,1.38,0,0,0,33.38,0h-32a1.38,1.38,0,1,0,0,2.75H32V15.19a1.37,1.37,0,0,0,1.37,1.38h8.36a1.43,1.43,0,0,0,.85-.29l1-.81,1.63.75V21.8H42.54a5.15,5.15,0,0,0-9.31,0H18.62a5.15,5.15,0,0,0-9.31,0H4.75a1.38,1.38,0,0,0,0,2.75H8.84a5.16,5.16,0,0,0,10.26,0H32.75a5.16,5.16,0,0,0,10.27,0h3.6A1.37,1.37,0,0,0,48,23.18V15.35A1.36,1.36,0,0,0,47.2,14.1Zm-12.44-.28V9h2.89l3.83,4.61-.21.17ZM14,26.41A2.41,2.41,0,1,1,16.38,24,2.41,2.41,0,0,1,14,26.41Zm23.92,0a2.41,2.41,0,1,1,2.4-2.4A2.41,2.41,0,0,1,37.89,26.41Z" />
      <path d="M15.63,9a1.38,1.38,0,1,0,0-2.75H4.75A1.38,1.38,0,0,0,4.75,9Z" />
      <path d="M13.67,13.86a1.37,1.37,0,0,0-1.38-1.37H1.38a1.38,1.38,0,1,0,0,2.75H12.29A1.38,1.38,0,0,0,13.67,13.86Z" />
    </svg>
  )
}

DeliverySvg.propTypes = {
  className: PropTypes.string,
  category: PropTypes.string,
  action: PropTypes.string,
  label: PropTypes.string,
}

export default DeliverySvg
