import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import Helmet from 'react-helmet'
import { setupAnalytics } from '@helpers/google-tag-manager'
import { getOrder } from '@services/checkout'
import { setDeliveryInfo } from '@helpers/checkout/delivery-section'
import { getRegionZone } from '@helpers/geo-location'
import { getLastParamOfPath, currencyFormatUS } from '@helpers/string-helper'
import { getRoomsToGoDeliveryItems, getOtherDeliveryItems } from '@helpers/cart'
import { productPrice, fetchProductOrGC } from '@helpers/product'
import { Grid, Link, Typography } from '@material-ui/core'
import PaymentSuccessCards from '@shared/payment-success-cards'
import { clearCheckoutState } from '@helpers/checkout/global'
import CondensedContact from '../../components/shared/condensed-contact-links'
import Layout from '../../components/layout'
import DeliveryProduct from '../../components/checkout/checkout-parts/delivery-section/delivery-product'
import Delivery from '../../components/order/delivery'
import ProductTile from '../../components/product/product-tile'
import SimpleSlider from '../../components/shared/slider'

function OrderSuccess({
  rtg_location,
  shipping_address,
  cart,
  isLandscape,
  onSetCart,
  onSetLocation,
  onSetOrder,
  onSetShippingAddress,
}) {
  const [order, setOrder] = useState({})
  const orderId = getLastParamOfPath()
  const { region } = getRegionZone()

  useEffect(() => {
    setupAnalytics({ pageData: { type: 'order', title: 'Order Success', path: '/order-success' } })
  }, [])

  const getOrderData = useCallback(async () => {
    const orderData = await getOrder({ orderId })
    const cartItemPromises = []
    const lineItems =
      orderData.lineItems && orderData.lineItems.filter(item => item.sku !== '99999925' && !item.required)
    lineItems.forEach(item => {
      cartItemPromises.push(
        fetchProductOrGC(item.sku).then(product => ({
          sku: product.sku,
          quantity: item.quantity,
          warrantyEnabled: item.warrantyEnabled,
          product,
          price: currencyFormatUS(productPrice(product)),
        })),
      )
    })

    const cartItems = await Promise.all(cartItemPromises)

    orderData.cartItems = cartItems
    orderData.rtgDeliveryItems = getRoomsToGoDeliveryItems(cartItems)
    orderData.otherDeliveryItems = getOtherDeliveryItems(cartItems)
    if (orderData.deliveryInfo) {
      orderData.deliveryInfo = {
        dates: orderData.deliveryCalendar.map(date => ({
          readable: '',
          date,
          isPickup: false,
          isStandardDelivery: true,
        })),
        deliveryDate: orderData.deliveryDate,
        pickup: false,
      }
    }
    setOrder(orderData)
    clearCheckoutState(true)
  }, [orderId])

  useEffect(() => {
    getOrderData()
  }, [getOrderData])

  const isKlarnaOrder = order?.paymentInfo?.map(payment => payment.paymentType).includes('KLN')
  return (
    <Layout
      rtg_location={rtg_location}
      order={order}
      shipping_address={shipping_address}
      cart={cart}
      isLandscape={isLandscape}
      onSetCart={onSetCart}
      onSetLocation={onSetLocation}
      onSetOrder={onSetOrder}
      onSetShippingAddress={onSetShippingAddress}
    >
      <Helmet title="Order Successful - Rooms To Go" />
      {order && (
        <div className="order-success grid-container">
          <div className="grid-x cell small-12">
            <div className="grid-x cell small-12 order-success-content">
              {order.cartItems && order.cartItems.length > 0 && (
                <div className="grid-x grid-margin-y cell small-12">
                  <div className="grid-x grid-margin-y cell small-12 large-8">
                    <div className="cell small-12 large-12">
                      <h1>CONGRATULATIONS!</h1>
                      <h2>Your order is being processed.</h2>
                      {order.paymentInfo?.length > 0 && !order.paymentInfo?.find(el => el.paymentType === 'BIT') && (
                        <h2>
                          Note: Due to unusually high volume, it may take up to 12 hours to receive your email
                          confirmation.
                        </h2>
                      )}
                      <div className="confirmation-email">
                        {`A confirmation email will be sent to ${order.contact.email}`}
                      </div>
                      {!!isKlarnaOrder && (
                        <Grid container justify="flex-start" alignItems="center">
                          <Grid item xs={2}>
                            <img
                              src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"
                              alt="Klarna"
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Link href="https://www.klarna.com/us/klarna-app/" target="_blank">
                              <Typography variant="caption">Download the Klarna App</Typography>
                            </Link>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                    <div className="grid-x cell small-12 order-success-product-totals card">
                      <div className="cell small-12 order-success-product">
                        <h3>Your Order</h3>
                        {order.cartItems.map(item => (
                          <DeliveryProduct
                            key={item.product.sku}
                            product={item.product}
                            quantity={item.quantity}
                            warrantyEnabled={item.warrantyEnabled}
                            index={1}
                          />
                        ))}
                      </div>
                      <div className="cell small-12 large-6 order-success-totals right">
                        <ul>
                          <li>
                            <span className="">Sub-Total</span>
                            <span className="right bold">
                              {currencyFormatUS(order.total - order.tax - order.totalDeliveryCharge, true)}
                            </span>
                          </li>
                          <li>
                            <span className="">Shipping & Delivery</span>
                            <span className="right">{currencyFormatUS(order.totalDeliveryCharge, true)}</span>
                          </li>
                          <li>
                            <span className="">Sales Tax</span>
                            <span className="right">{currencyFormatUS(order.tax, true)}</span>
                          </li>
                          <hr />
                          <li>
                            <span className="bold">Total</span>
                            <span className="right bolder">{currencyFormatUS(order.total, true)}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="grid-x cell small-12 order-success-delivery card">
                      <div className="cell small-12">
                        <h3>Delivery</h3>
                        <Delivery
                          cart={cart}
                          order={order}
                          deliveryInfo={order.deliveryInfo}
                          setDeliveryInfo={setDeliveryInfo} //  Legacy component didnt import this?
                          rtgDeliveryItems={order.rtgDeliveryItems}
                          otherDeliveryItems={order.otherDeliveryItems}
                          activeMenu=""
                          completedCheckoutSteps={5}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid-x grid-margin-y cell small-12 large-4 order-success-shipping-payment">
                    <div className="cell small-12 card order-success-shipping">
                      <h3>Shipping & Delivery Address</h3>
                      <div>{`${order.contact.firstName} ${order.contact.lastName}`}</div>
                      <div>{order.shippingAddress.address1}</div>
                      <div>{order.shippingAddress.address2}</div>
                      <div>{`${order.shippingAddress.city}, ${order.shippingAddress.state} ${order.shippingAddress.zip}`}</div>
                      <br />
                      <div>{order.contact.email}</div>
                      <div>{order.contact.phone}</div>
                      {order.contact.altPhone && <div>{`Alternate Phone: ${order.contact.altPhone}`}</div>}
                    </div>
                    <div className="cell small-12 card order-success-payment">
                      <h3>Payment</h3>
                      <h4>Billing Information</h4>
                      {order.billingAddress && order.billingAddress.address1 && (
                        <>
                          {order.payer && <div>{`${order.payer.firstName} ${order.payer.lastName}`}</div>}
                          {!order.payer && <div>{`${order.contact.firstName} ${order.contact.lastName}`}</div>}
                          <div>{order.billingAddress.address1}</div>
                          <div>{order.billingAddress.address2}</div>
                          <div>{`${order.billingAddress.city}, ${order.billingAddress.state} ${order.billingAddress.zip}`}</div>
                          <br />
                        </>
                      )}
                      {!order.billingAddress && order.shippingAddress && order.shippingAddress.address1 && (
                        <>
                          <div>{`${order.contact.firstName} ${order.contact.lastName}`}</div>
                          <div>{order.shippingAddress.address1}</div>
                          <div>{order.shippingAddress.address2}</div>
                          <div>{`${order.shippingAddress.city}, ${order.shippingAddress.state} ${order.shippingAddress.zip}`}</div>
                          <br />
                        </>
                      )}
                      {order && order.paymentInfo && <PaymentSuccessCards paymentInfo={order.paymentInfo} />}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {order?.cartItems?.[0]?.product?.also_in_collection?.[region] && (
              <div className="cell small-12">
                <SimpleSlider
                  data={{
                    heading: 'YOU MAY ALSO LIKE',
                  }}
                  maxSlides={4}
                  noExtraMargin
                >
                  {order.cartItems[0].product.also_in_collection[region].map((product, index) => (
                    <ProductTile sku={product.sku} key={product.sku} viewType="grid" index={index} />
                  ))}
                </SimpleSlider>
              </div>
            )}
            <div className="cell small-12">
              <CondensedContact />
            </div>
          </div>
        </div>
      )}
      {/* If order object is NULL still display a success message.
          This happens when an store cart item is not available online.
      */}
      {/* TODO: figure out a long-term solution for this hack */}
      {!order ||
        (Object.keys(order).length === 0 && (
          <div className="order-success grid-container">
            <div className="grid-x cell small-12">
              <div className="grid-x cell small-12 order-success-content">
                <div className="grid-x grid-margin-y cell small-12">
                  <div className="grid-x grid-margin-y cell small-12 large-8">
                    <div className="cell small-12 large-12">
                      <h1>CONGRATULATIONS!</h1>
                      <h2>Your order is being processed.</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </Layout>
  )
}

OrderSuccess.propTypes = {
  cart: PropTypes.any,
  isLandscape: PropTypes.any,
  onSetCart: PropTypes.any,
  onSetLocation: PropTypes.any,
  onSetOrder: PropTypes.any,
  onSetShippingAddress: PropTypes.any,
  rtg_location: PropTypes.any,
  shipping_address: PropTypes.any,
}

export default OrderSuccess
