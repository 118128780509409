import React from 'react'
import { object } from 'prop-types'
import '../../assets/css/components/order/delivery.sass'
import { currencyFormatUS, months } from '@helpers/string-helper'
import { splitDates } from '@helpers/checkout/delivery-section'
import {
  DOORWAY_DELIVERY_FREE_SHIPPING_THRESHOLD,
  DOORWAY_DELIVERY_IMAGE,
} from '@components/checkout/checkout-parts/delivery-section/constants'
import { getDateFull } from '@helpers/date'
import RTGLink from '@shared/link'
import DeliverySvg from '@shared/svgs/deliverySvg'
import SplitDeliverySummary from '@components/shared/split-delivery-summary'
import { getRegionZone } from '@helpers/geo-location'
import DeliveryProduct from '../checkout/checkout-parts/delivery-section/delivery-product'
import DistributionCenterPopup from '../checkout/checkout-parts/delivery-section/distribution-center-popup'

const regionInPR = getRegionZone().region === 'PR'

export const generateAutomatedMessage = ({ isExpress, calendarType, doorwayDelivery }) => {
  if (isExpress && doorwayDelivery) {
    return (
      <>
        <p>*You will be notified of the delivery window prior to delivery.</p>
        <p>
          You will be notified of the estimated delivery window 2 days prior to delivery.&nbsp;
          <span className="bold">Does not include professional assembly or removal of packaging/materials. </span>By
          selecting Doorway Delivery, you agree that you are solely responsible for assembly as set forth in the{' '}
          <RTGLink
            action="click"
            style={{ 'line-height': '0.7rem' }}
            data={{
              url: regionInPR
                ? 'https://vault.pactsafe.io/s/e9b4b02e-6b86-4043-82f7-abd86913713a/legal.html#contract-rjvll6vhc'
                : 'https://misc.rtg-prod.com/rtgcom-terms-of-sale.pdf',
              title: 'Rooms To Go Terms of Use',
              action: 'click',
              target: '_blank',
            }}
          >
            Online Terms and Conditions of Sale, Limited Product Warranty and Dispute Resolution/Arbitration Agreement
          </RTGLink>
          .
        </p>
      </>
    )
  }
  if (isExpress) {
    return '*You will be notified of the delivery window prior to delivery.'
  }
  if (calendarType && calendarType === 'weekly') {
    return 'You will receive a call on the Thursday prior to the date that you selected. This call will identify the specific date and time that your merchandise will be delivered, within 12 days from the Monday you choose for your departure date.'
  }
  if (doorwayDelivery) {
    return (
      <p>
        You will be notified of the estimated delivery window 2 days prior to delivery.&nbsp;
        <span className="bold">Does not include professional assembly or removal of packaging/materials. </span>By
        selecting Doorway Delivery, you agree that you are solely responsible for assembly as set forth in the{' '}
        <RTGLink
          action="click"
          style={{ 'line-height': '0.7rem' }}
          data={{
            url: regionInPR
              ? 'https://vault.pactsafe.io/s/e9b4b02e-6b86-4043-82f7-abd86913713a/legal.html#contract-rjvll6vhc'
              : 'https://misc.rtg-prod.com/rtgcom-terms-of-sale.pdf',
            title: 'Rooms To Go Terms of Use',
            action: 'click',
            target: '_blank',
          }}
        >
          Online Terms and Conditions of Sale, Limited Product Warranty and Dispute Resolution/Arbitration Agreement
        </RTGLink>
        .
      </p>
    )
  }
  return 'You will be notified of the estimated delivery window 2 days prior to delivery.'
}

export const generateDeliveryInfo = ({ isExpress, calendarType }) => {
  if (calendarType !== 'weekly') {
    return '4 hour delivery window.'
  }
  return ''
}

const OrderDelivery = ({ order }) => {
  const {
    isPickup,
    defaultDelivery,
    doorwayDelivery,
    doorwayDeliveryCharge,
    totalDeliveryCharge,
    rtgDeliveryItems,
    splitDeliveryDates = null,
  } = order
  const allProduct = [...rtgDeliveryItems, ...order.otherDeliveryItems]
  const otherDeliveryItems =
    order.otherDeliveryItems && order.otherDeliveryItems.filter(item => item.product.delivery_type !== 'T')
  const giftCards =
    order.otherDeliveryItems && order.otherDeliveryItems.filter(item => item.product.delivery_type === 'T')
  const deliveryDate = getDateFull(order.deliveryDate)
  const possibleDates = order && order.defaultDelivery ? splitDates(order.deliveryDate) : '2019-11-21'
  return (
    <div className="grid-x grid-padding-x grid-padding-y small-margin-collapse order-delivery-section">
      {rtgDeliveryItems.length > 0 && splitDeliveryDates && <SplitDeliverySummary order={order} />}
      {isPickup && !splitDeliveryDates && (
        <div className="cell small-12 delivery-form">
          <div className="cell small-12 delivery-heading">
            <h4>
              Pick-up from <DistributionCenterPopup order={order} /> on: {deliveryDate}
            </h4>
          </div>
          <div className="delivery-product-container cell small-12">
            {allProduct.map((item, index) => (
              <DeliveryProduct
                key={item.product.sku}
                product={item.product}
                productCount={allProduct.length}
                quantity={item.quantity}
                index={index}
                noImage
                warrantyEnabled={item.warrantyEnabled}
              />
            ))}
          </div>
        </div>
      )}
      {!isPickup && rtgDeliveryItems.length > 0 && !splitDeliveryDates && (
        <div className="cell small-12 medium-6 large-6 delivery-form">
          <div className="cell small-12 delivery-heading">
            <h4>
              {doorwayDelivery ? (
                <img
                  className="icon shipping"
                  alt=""
                  aria-hidden="true"
                  role="presentation"
                  src={DOORWAY_DELIVERY_IMAGE}
                />
              ) : (
                <DeliverySvg className="icon shipping" />
              )}

              {doorwayDelivery && order.total > DOORWAY_DELIVERY_FREE_SHIPPING_THRESHOLD && 'Doorway Delivery'}
              {doorwayDelivery && order.total <= DOORWAY_DELIVERY_FREE_SHIPPING_THRESHOLD && 'Free Doorway Delivery'}
              {!doorwayDelivery && 'Premium Rooms To Go Delivery and Set-Up'}
            </h4>
          </div>
          <div className="cell small-12 delivery-info">
            {defaultDelivery && (
              <p>
                <span>Estimated Delivery Date: </span>
                <span>
                  {`${months[possibleDates.deliverDate.getMonth()]} ${possibleDates.deliverDate.getDate()}`} -{' '}
                  {`${months[possibleDates.fortNight.getMonth()]} ${possibleDates.fortNight.getDate()}`}
                </span>
              </p>
            )}
            {!defaultDelivery && (
              <div>
                {doorwayDelivery ? 'Placed inside the entryway or garage on: ' : 'Delivery On: '}
                <span className="bold" data-testid="delivery-date">
                  {deliveryDate}
                </span>
              </div>
            )}
            <div className="bold">{generateDeliveryInfo(order)}</div>
            <div className="bold">
              {currencyFormatUS(doorwayDelivery ? doorwayDeliveryCharge : totalDeliveryCharge)}
            </div>
            <div className="cell small-12 automated-message">{generateAutomatedMessage(order)}</div>
          </div>
          <div className="delivery-product-container cell small-12">
            {rtgDeliveryItems.map((item, index) => (
              <DeliveryProduct
                key={item.product.sku}
                product={item.product}
                productCount={rtgDeliveryItems.length}
                quantity={item.quantity}
                index={index}
                noImage
                warrantyEnabled={item.warrantyEnabled}
              />
            ))}
          </div>
        </div>
      )}
      {!isPickup && otherDeliveryItems.length > 0 && !splitDeliveryDates && (
        <div className="cell small-12 medium-6 large-6 delivery-form">
          <div className="cell small-12 delivery-heading">
            <h4>
              <DeliverySvg className="icon shipping" />
              Shipped to your door
            </h4>
          </div>
          <div className="cell small-12 delivery-info">
            <span className="bold">FREE </span>
            <span>Shipping directly from vendor</span>
          </div>
          <div className="delivery-product-container no-border cell small-12">
            {otherDeliveryItems.map((item, index) => (
              <DeliveryProduct
                key={item.product.sku}
                product={item.product}
                productCount={order.otherDeliveryItems.length}
                quantity={item.quantity}
                index={index}
                noImage
                warrantyEnabled={item.warrantyEnabled}
              />
            ))}
          </div>
        </div>
      )}
      {!isPickup && giftCards.length > 0 && (
        <div className="cell small-12 medium-6 large-6 delivery-form">
          <div className="cell small-12 delivery-heading">
            <h4>
              <DeliverySvg className="icon shipping" />
              Shipped to your door
            </h4>
          </div>
          <div className="cell small-12 delivery-info">
            <span className="bold">FREE </span>
            <span>Shipped via USPS</span>
          </div>
          <div className="delivery-product-container no-border cell small-12">
            {giftCards.map((item, index) => (
              <DeliveryProduct
                key={item.product.sku}
                product={item.product}
                productCount={order.otherDeliveryItems.length}
                quantity={item.quantity}
                index={index}
                noImage
                warrantyEnabled={item.warrantyEnabled}
              />
            ))}
          </div>
        </div>
      )}
      {!isPickup && order.calendarType === 'weekly' && (
        <div className="cell small-12 automated-message" data-testid="automated-message">
          *You will receive a call on the Thursday prior to the date that you selected. This call will identify the
          specific date and time that your merchandise will be delivered, within 12 days from the Monday you choose for
          your departure date.' : '*You will be notified of the estimated delivery window 2 days prior to delivery.
        </div>
      )}
    </div>
  )
}

OrderDelivery.propTypes = {
  order: object,
}

export default OrderDelivery
