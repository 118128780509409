export const PROFESSIONAL_DELIVERY = 'delivery'
export const DOORWAY_DELIVERY = 'doorway'
export const PICK_UP = 'pickup'

export const EXPRESS_DELIVERY_IMAGE =
  'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%220%200%2096.4%2049.1%22%3E%3Ctitle%3Eicon-shipping%3C%2Ftitle%3E%3Cg%20id%3D%22Layer_2%22%20data-name%3D%22Layer%202%22%3E%3Cg%20id%3D%22Layer_1-2%22%20data-name%3D%22Layer%201%22%3E%3Cpath%20d%3D%22M26.3%2C43.2H33a1.27%2C1.27%2C0%2C0%2C0%2C.6-.1%2C7%2C7%2C0%2C0%2C0%2C13.8.1h28a7%2C7%2C0%2C0%2C0%2C13.8%2C0h5.5a1.75%2C1.75%2C0%2C0%2C0%2C1.7-1.7V22.8c0-4.8-1.3-8.7-3.9-11.5a12.75%2C12.75%2C0%2C0%2C0-9.5-4H73V1.7A1.75%2C1.75%2C0%2C0%2C0%2C71.3%2C0h-45a1.75%2C1.75%2C0%2C0%2C0-1.7%2C1.7V41.5A1.75%2C1.75%2C0%2C0%2C0%2C26.3%2C43.2Zm56%2C2.4A3.6%2C3.6%2C0%2C1%2C1%2C85.9%2C42%2C3.59%2C3.59%2C0%2C0%2C1%2C82.3%2C45.6ZM73%2C10.7H83c6.2%2C0%2C10%2C4.6%2C10%2C12v17H88.9A7.14%2C7.14%2C0%2C0%2C0%2C82.3%2C35a7%2C7%2C0%2C0%2C0-6.6%2C4.7H73.1v-29ZM40.6%2C45.6A3.6%2C3.6%2C0%2C1%2C1%2C44.2%2C42%2C3.72%2C3.72%2C0%2C0%2C1%2C40.6%2C45.6ZM28%2C3.4H69.5V39.7H47.8a1.27%2C1.27%2C0%2C0%2C0-.6.1A7%2C7%2C0%2C0%2C0%2C40.5%2C35a7.12%2C7.12%2C0%2C0%2C0-6.7%2C4.9%2C1.88%2C1.88%2C0%2C0%2C0-.8-.2H28ZM0%2C11.8a1.75%2C1.75%2C0%2C0%2C1%2C1.7-1.7H18.1a1.7%2C1.7%2C0%2C0%2C1%2C0%2C3.4H1.7A1.75%2C1.75%2C0%2C0%2C1%2C0%2C11.8ZM18.1%2C23.3H4.3a1.7%2C1.7%2C0%2C0%2C1%2C0-3.4H18.1a1.7%2C1.7%2C0%2C0%2C1%2C0%2C3.4Zm-13.2%2C8a1.75%2C1.75%2C0%2C0%2C1%2C1.7-1.7H18A1.7%2C1.7%2C0%2C1%2C1%2C18%2C33H6.6A1.63%2C1.63%2C0%2C0%2C1%2C4.9%2C31.3Z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E'

export const DISTRIBUTION_CENTER_IMAGE =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTQgMTB2N2gzdi03SDR6bTYgMHY3aDN2LTdoLTN6TTIgMjJoMTl2LTNIMnYzem0xNC0xMnY3aDN2LTdoLTN6bS00LjUtOUwyIDZ2MmgxOVY2bC05LjUtNXoiLz48L3N2Zz4='

export const DOORWAY_DELIVERY_IMAGE =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCIgPgogIDxwYXRoIGQ9Ik0xOS4zIDguNWMuMy4zLjYuNi45LjhsMy42IDMuM2MuNC4zLjcuNyAxLjEgMSAuMS4xLjEuMS4xLjJ2Ni40YzAgLjQtLjIuNi0uNi42SDIxdi0uMi0zLjNjMC0uNC0uMi0uNi0uNi0uNmgtMi41Yy0uNCAwLS41LjItLjUuNnYzLjVoLTEuM3YtLjItMS45YzAtLjMtLjEtLjYtLjMtLjgtLjctLjktMS41LTEuNy0yLjItMi41IDAgMC0uMS0uMS0uMS0uMnYtMS40YzAtLjEgMC0uMS4xLS4yLjYtLjYgMS4yLTEuMSAxLjgtMS43LjgtLjcgMS42LTEuNCAyLjMtMi4xLjYtLjQgMS4xLS44IDEuNi0xLjN6Ii8+CiAgPHBhdGggZD0iTTE1LjMgMTkuNUgyLjV2LS43LTMuM2MwLS40LjMtLjcuNy0uN2g3Yy40IDAgLjcuMi43Ljd2LjRIMTIuNWMuMyAwIC41LjEuNi4zLjYuNyAxLjIgMS40IDEuOSAyLjIuMS4xLjIuMi4zLjR2Ljd6bS0xLjMtMWMwLS4xLS4xLS4xLS4xLS4xLS40LS41LS45LTEuMS0xLjMtMS42bC0uMS0uMWgtLjh2MS44SDE0ek0xMS4xIDEzLjhjLjMtLjMuNi0uNS45LS44IDEuMi0xLjEgMi40LTIuMiAzLjYtMy40IDEtLjkgMS45LTEuOCAyLjktMi43bC4zLS4zYy4xLS4xLjgtLjEgMSAwIDEuMSAxIDIuMiAyLjEgMy40IDMuMWwuMS4xdi0yaDJ2My42YzAgLjEgMCAuMi4xLjMuNy42IDEuNCAxLjMgMi4xIDEuOWwuMS4xaC0xLjVjLS4xIDAtLjIgMC0uMy0uMS0uNy0uNi0xLjQtMS4yLTItMS45LS44LS44LTEuNy0xLjUtMi41LTIuMy0uNy0uNC0xLjMtLjktMS45LTEuNCAwIDAtLjEtLjEtLjItLjEtLjMuMi0uNS41LS44LjctMS44IDEuNy0zLjcgMy40LTUuNSA1LjEtLjIuMi0uNC4zLS42LjItLjQgMC0uOCAwLTEuMi0uMSAwIC4xIDAgMCAwIDB6TTEwLjcgMjEuOEg3LjJjLS4xLS41LS4zLS44LS43LTEuMS0uMy0uMi0uNi0uMy0uOS0uMy0uNiAwLTEuNS40LTEuNyAxLjRIM2MtLjIgMC0uNS0uMy0uNS0uNXYtMS40aDEyLjh2MS40YzAgLjMtLjMuNi0uNi42SDE0Yy0uNC0xLjEtLjktMS41LTEuOS0xLjQtLjUgMC0xLjIuMy0xLjQgMS4zeiIvPgogIDxwYXRoIGQ9Ik01LjYgMjMuNGMtLjcgMC0xLjMtLjYtMS4zLTEuMiAwLS43LjYtMS40IDEuMy0xLjQuNyAwIDEuMy42IDEuMyAxLjMgMCAuNy0uNiAxLjMtMS4zIDEuM3ptMC0xLjdjLS4yIDAtLjMuMS0uMy4zIDAgLjIuMS4zLjMuMy4yIDAgLjQtLjEuNC0uMy0uMS0uMS0uMi0uMy0uNC0uM3pNMTIuNCAyMy40Yy0uNyAwLTEuMy0uNi0xLjMtMS4zIDAtLjcuNi0xLjMgMS4zLTEuMy43IDAgMS4zLjYgMS4zIDEuMyAwIC43LS42IDEuMy0xLjMgMS4zem0uMy0xLjNjMC0uMi0uMS0uMy0uMy0uMy0uMiAwLS40LjItLjQuMyAwIC4yLjEuMy4zLjMuMyAwIC40LS4xLjQtLjN6Ii8+Cjwvc3ZnPg=='

export const DOORWAY_DELIVERY_FREE_SHIPPING_THRESHOLD = 1199.99
